import { NavLink } from "react-router-dom";
import { UserType } from "../../interfaces/User";
import { getUrl } from "../../utils/dataRetrieval";
import { Page, Pagetype } from "../../interfaces/Config";
import { filterDefined } from "../../utils/array-manipulations";

const WORKMODES_PAGETYPES = [
    Pagetype.MODULELIST,
    Pagetype.WORKMODE_DUO,
    Pagetype.WORKMODE_PLAYLIST,
    Pagetype.WORKMODE_TUTO,
    Pagetype.WORKMODE_WORKSHOP,
    Pagetype.WORK_MODES,
    Pagetype.WORKMODE_EXPERIMENTATION,
];

export const isWorkmodeNavActive =
    (
        pages: Page[],
        userType: UserType
    ): Parameters<typeof NavLink>[0]["isActive"] =>
    (_, location) => {
        const workmodeRoutes = WORKMODES_PAGETYPES.map((type) =>
            pages.find((page) => page.type === type)
        )
            .filter(filterDefined)
            .map((page) => getUrl(page, userType));
        return workmodeRoutes.some((route) =>
            location.pathname.replace("/", "").startsWith(route)
        );
    };
