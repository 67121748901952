import React, { useContext } from "react";

import "./MobileNav.scss";
import { NavLink } from "react-router-dom";
import { getUrl } from "../../../utils/dataRetrieval";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { defineMessages, useIntl } from "react-intl";
import { UserType } from "../../../interfaces/User";
import { Pagetype } from "../../../interfaces/Config";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { isNavlinkActive, showInHeader } from "../utils";
import { headerIcons } from "../interface";
import { withBreakpoint } from "../../../utils/withBreakpoint";
import { getPageTitle } from "../../../utils/pages";
import { useFeatureFlags } from "@evidenceb/athena-common/modules/FeatureFlags";
import { useUserType } from "../../../hooks/useUserInfo";

function MobileNav() {
    const intl = useIntl();
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const features = useFeatureFlags();
    const userType = useUserType();

    const itemList = config.pages.filter((page) => {
        return (
            showInHeader(session.userType, page) &&
            page.type !== Pagetype.LOGOUT &&
            page.type !== Pagetype.SWITCH_USER_TYPE
        );
    });

    return (
        <>
            <header className="footer-main-container">
                <div className="footer-container">
                    <nav
                        role="navigation"
                        aria-label={intl.formatMessage(messages.mainMenu)}
                    >
                        <ul>
                            {itemList.map((page, i) =>
                                page.type === Pagetype.EXTERNAL ? (
                                    <a
                                        key={`navItem-${page.type}-${i}`}
                                        rel="noreferrer"
                                        href={getUrl(page, userType)}
                                        target="_blank"
                                    >
                                        {page.display.label}
                                    </a>
                                ) : (
                                    <li key={`navItem-${page.type}-${i}`}>
                                        <NavLink
                                            className={`${
                                                session.userType ===
                                                UserType.Teacher
                                                    ? "--teacher"
                                                    : "--student"
                                            }`}
                                            exact
                                            to={`/${getUrl(
                                                page,
                                                session.userType
                                            )}`}
                                            title={getPageTitle(intl)(
                                                page,
                                                session.userType,
                                                features
                                            )}
                                            isActive={isNavlinkActive(
                                                page,
                                                config.pages,
                                                userType
                                            )}
                                        >
                                            <Icon
                                                className="footer-container__icon --icon-idle"
                                                path={
                                                    "nav-bar_" +
                                                        headerIcons[page.type] +
                                                        "_outline" || ""
                                                }
                                                size={20}
                                            />
                                            <Icon
                                                className="footer-container__icon --icon-active"
                                                path={
                                                    "nav-bar_" +
                                                        headerIcons[
                                                            page.type
                                                        ] || ""
                                                }
                                                style={{
                                                    color: "var(--element-evidenceb-default)",
                                                }}
                                                size={20}
                                            />
                                            <span>
                                                {getPageTitle(intl)(
                                                    page,
                                                    session.userType,
                                                    features
                                                )}
                                            </span>
                                        </NavLink>
                                    </li>
                                )
                            )}
                        </ul>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default withBreakpoint(MobileNav, "isMobile");

const messages = defineMessages({
    mainMenu: {
        id: "a11y-mainMenu",
        defaultMessage: "Main menu",
    },
});
