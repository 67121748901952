import React, {
    PropsWithChildren,
    isValidElement as isReactNode,
    ReactNode,
    useRef,
    useState,
    useEffect,
} from "react";
import cn from "classnames";

import { useBreakpoints } from "@evidenceb/athena-common/helpers/breakpoints";
import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { commonMessages } from "../../utils/messages";
import useHideOnScroll from "../../hooks/ui/useHideOnScroll";

import "./PageHeader.scss";
import UserTypeSwitch from "../Header/UserTypeSwitch/UserTypeSwitch";
import { isSpecimenVersion } from "../../utils/init";
import classNames from "classnames";
import { Image } from "@evidenceb/athena-common/modules/Assets";
import useAssetsDetails from "../../hooks/useAssetsDetails";

export type Props = PropsWithChildren<{
    title: {
        content: string | ReactNode;
        size?: "default" | "large";
    };
    children?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    /**
     * URL the backlink should point to. Defaults to using history.goBack
     */
    backTo?: string;
    /**
     * URL the backlink points to if history.goBack has no effect because there
     * is no history
     */
    fallbackBackTo?: string;
    /**
     * Remove chevron icon and backTo link
     * @default false
     */
    noBackBtn?: boolean;
    className?: string;
    background?: string;
}>;

const PageHeader = ({
    title,
    children,
    className,
    backTo,
    fallbackBackTo,
    noBackBtn,
    background,
}: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const { isMobile } = useBreakpoints();

    const ref = useRef<HTMLDivElement>(null);
    const { styles: scrollStyle } = useHideOnScroll(ref);
    const assetsDetails = useAssetsDetails();

    const [isAtTop, setIsAtTop] = useState(true);
    useEffect(() => {
        const handleScroll = () => {
            setIsAtTop(window.scrollY === 0);
        };
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header
            ref={ref}
            className={cn("page-header", className, {
                "--is-scrolling": !isAtTop,
            })}
            style={scrollStyle}
        >
            <div className="page-header__content">
                <div
                    className={classNames(
                        "page-header__title",
                        `--${title.size ? title.size : "default"}`
                    )}
                >
                    {!noBackBtn && (
                        <button
                            onClick={() => {
                                if (backTo) history.push(backTo);
                                else if (history.length <= 1 && fallbackBackTo)
                                    history.push(fallbackBackTo);
                                else history.goBack();
                            }}
                        >
                            <Icon
                                path="chevron_left"
                                size={isMobile ? "medium" : "large"}
                                color="var(--text-main-default)"
                            />
                            <VisuallyHidden as="span">
                                {intl.formatMessage(commonMessages.goBack)}
                            </VisuallyHidden>
                        </button>
                    )}
                    {isReactNode(title.content) ? (
                        title.content
                    ) : (
                        <h1>{title.content}</h1>
                    )}
                    {isMobile && isSpecimenVersion() && <UserTypeSwitch />}
                </div>
                {isMobile && children && (
                    <div className="page-header__children">{children}</div>
                )}
            </div>

            {!isMobile && children}
            {background && (
                <div className="page-header__background">
                    <Image
                        assetsDetails={assetsDetails}
                        src={background}
                        aria-hidden={true}
                        alt=""
                    />
                </div>
            )}
        </header>
    );
};
export default PageHeader;
