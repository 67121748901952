import React from "react";
import "./Preview.scss";
import TabTitle from "../../../components/TabTitle/TabTitle";

type CustomJSON = {
    title?: string;
    image?: string;
};

interface PreviewProps {
    displayParam: string | null;
}

const Preview: React.FC<PreviewProps> = ({ displayParam }) => {
    let customJSON: CustomJSON = {};
    if (displayParam) {
        customJSON = JSON.parse(atob(displayParam));
        console.log(customJSON);
    }
    return (
        <>
            <TabTitle
                title={customJSON.title ?? ""}
                ogTitle={customJSON.title}
                ogImage={customJSON.image}
                omitClientName
            />
            <div className="preview-screen">
                <img
                    className="preview-image"
                    src={customJSON.image}
                    alt={customJSON.title}
                />
            </div>
        </>
    );
};

export default Preview;
