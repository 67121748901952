import React from "react";
import useTheme from "../../hooks/useTheme";
import classNames from "classnames";
import { AssetsDetails, Image } from "@evidenceb/athena-common/modules/Assets";
import "./ImageWithFallback.scss";

interface Props {
    fallbackImage: string | undefined;
    src?: string;
    assetsDetails?: AssetsDetails;
    size?: "small" | "default";
    className?: string;
}

const ImageWithFallback = ({
    fallbackImage,
    src,
    assetsDetails,
    className,
    size = "default",
}: Props) => {
    const theme = useTheme();
    return src && assetsDetails ? (
        <Image src={src} assetsDetails={assetsDetails} />
    ) : (
        <img
            className={classNames("app-default-image", className, `--${size}`)}
            src={`/images/default/${theme}/${fallbackImage}`}
            alt=""
        />
    );
};

export default ImageWithFallback;
