import React from "react";
import {
    NotificationAggregate,
    NotificationStatus,
    StudentInfo,
} from "../Types";
import Notification from "../Notification/Notification";
import { useImages } from "../../../hooks/useConfig";
import EmptyContainer from "../../../design-system-components/WorkmodeLayout/EmptyContainer/EmptyContainer";
import { defaultImages } from "../../../utils/defaultImages";

export type Props = {
    notifications: NotificationAggregate[];
    emptyMessage: string;
    onNotificationReadStatusChange: (
        id: string | undefined,
        students: StudentInfo[],
        status: NotificationStatus
    ) => void;
};

const NotificationsTab = ({
    notifications,
    emptyMessage,
    onNotificationReadStatusChange,
}: Props) => {
    const images = useImages();
    return (
        <>
            {notifications.length === 0 && (
                <div className="notification_no-notifications">
                    <EmptyContainer
                        img={images?.notifications?.emptyState}
                        defaultImg={defaultImages.notificationEmpty}
                    >
                        <p>{emptyMessage}</p>
                    </EmptyContainer>
                </div>
            )}

            {notifications.length > 0 && (
                <section className="notifications__list">
                    {notifications.map((notification) => {
                        return (
                            <Notification
                                key={notification.id}
                                notification={notification}
                                onNotificationReadStatusChange={
                                    onNotificationReadStatusChange
                                }
                            />
                        );
                    })}
                </section>
            )}
        </>
    );
};
export default NotificationsTab;
