import {
    AthenaEvent,
    AthenaEventType,
    ResultEventType,
} from "@evidenceb/athena-event-storage-schemas";
import { Context, ContextActivity, Statement } from "@xapi/xapi";
import { eventWorkmodeToWorkModeType } from "../events";
import {
    ADAPTIVE_TEST_CATEGORY,
    AI_CATEGORIES,
    VERBS,
    WORKMODES_CATEGORIES,
    XAPI_REGISTRY,
} from "./const";
import { WorkmodesWithStatements } from "./types";
import { durationToISO } from "./utils";
import { AIType } from "@evidenceb/ai-handler";
import { WorkModeType } from "@evidenceb/athena-common/interfaces/Workmode";

/** List of AthenaEvent types that can be converted into Statements. */
export const convertibleAthenaEventTypes: AthenaEventType[] = [
    "ResultExerciseEvent",
    "PlayerResourceViewedEvent",
    "AICurriculumFinishedEvent",
];

/**
 * Predicate returning whether an AthenaEvent can be converted into a Statement
 * or not.
 */
export const isConvertibleAthenaEvent = (
    athenaEvent: AthenaEvent<any, any>
): boolean => {
    return convertibleAthenaEventTypes.includes(athenaEvent.type);
};

export class InconvertibleAthenaEvent extends Error {
    constructor(public athenaEvent: AthenaEvent<any, any>) {
        super(
            `AthenaEvent with type ${athenaEvent.type} cannot be converted into a Statement`
        );
    }
}

/** Converts an AthenaEvent into the corresponding Statement. */
export const makeStatementFromAthenaEvent = (
    athenaEvent: AthenaEvent<any, any>,
    declinaison: string
): Statement => {
    switch (athenaEvent.type) {
        case "ResultExerciseEvent":
            return fromResultExerciseEvent(athenaEvent, declinaison);
        case "PlayerResourceViewedEvent":
            return fromPlayerResourceViewedEvent(athenaEvent, declinaison);
        case "AICurriculumFinishedEvent":
            return fromAICurriculumFinishedEvent(athenaEvent, declinaison);
        default:
            throw new InconvertibleAthenaEvent(athenaEvent);
    }
};

const fromAICurriculumFinishedEvent = (
    athenaEvent: AthenaEvent<"ai", "AICurriculumFinishedEvent">,
    declinaison: string
): Statement => {
    return {
        id: athenaEvent.id,
        timestamp: athenaEvent.createdAt,
        actor: {
            account: {
                name: athenaEvent.userId,
                homePage: `${XAPI_REGISTRY}/homepages/${
                    athenaEvent.app ?? declinaison
                }`,
            },
            objectType: "Agent",
        },
        verb: VERBS.completed,
        object: {
            objectType: "Activity",
            id: `${XAPI_REGISTRY}/module/${athenaEvent.data.moduleId}`,
            definition: {
                type: `${XAPI_REGISTRY}/activities/adaptive-test`,
            },
        },
        result: {
            completion: true,
            extensions: {
                [`${XAPI_REGISTRY}/extensions/adaptive-test-diagnosis`]:
                    athenaEvent.data.diagnosis,
            },
        },
        // TODO DOUBT: Should we have this (adaptiveTestNumber) in the Event Storage?
        // The adaptiveTestNumber is required for the CNED tests, a very specific
        // version of the application. Postponed for now.
        // context: {
        //     extensions: {
        //         [`${XAPI_REGISTRY}/extensions/adaptive-test`]:
        //             adaptiveTestNumber,
        //     },
        // },
        context: {
            contextActivities: {
                category: [
                    {
                        id: WORKMODES_CATEGORIES[
                            eventWorkmodeToWorkModeType(
                                athenaEvent.categoryData.workmode
                            ) as WorkmodesWithStatements
                        ],
                    },
                    ...(athenaEvent.data.aiEngine === "adaptiveTest"
                        ? [{ id: ADAPTIVE_TEST_CATEGORY }]
                        : [{ id: AI_CATEGORIES[AIType.BanditManchot] }]),
                ],
            },
        },
    };
};

const fromPlayerResourceViewedEvent = (
    athenaEvent: AthenaEvent<"player", "PlayerResourceViewedEvent">,
    declinaison: string
): Statement => {
    return {
        id: athenaEvent.id,
        timestamp: athenaEvent.createdAt,
        actor: {
            account: {
                name: athenaEvent.userId,
                homePage: `${XAPI_REGISTRY}/homepages/${
                    athenaEvent.app ?? declinaison
                }`,
            },
            objectType: "Agent",
        },
        verb: VERBS.viewed,
        context: {
            extensions: {
                [`${XAPI_REGISTRY}/extensions/imposed-viewing`]:
                    athenaEvent.data.imposedViewing,
            },
        },
        object: {
            objectType: "Activity",
            id: athenaEvent.data.url,
            definition: {
                type: `${XAPI_REGISTRY}/activities/activity-video-tutorial`,
            },
        },
    };
};

const eventToCategory = (
    athenaEvent: AthenaEvent<"result", ResultEventType>
): ContextActivity | undefined => {
    if (athenaEvent.data.testContext === "adaptive-test")
        return { id: ADAPTIVE_TEST_CATEGORY };
    if (athenaEvent.categoryData.workmode === "solo-ai")
        return { id: AI_CATEGORIES[AIType.BanditManchot] };
    if (athenaEvent.categoryData.workmode === "revision")
        return { id: AI_CATEGORIES[AIType.RevisionManager] };
    if (athenaEvent.categoryData.workmode === "standalone-adaptive-test")
        return { id: AI_CATEGORIES[AIType.CNEDAdaptiveTest] };
};

const fromResultExerciseEvent = (
    athenaEvent: AthenaEvent<"result", ResultEventType>,
    declinaison: string
): Statement => {
    return {
        id: athenaEvent.id,
        timestamp: athenaEvent.createdAt,
        actor: {
            account: {
                name: athenaEvent.userId,
                homePage: `${XAPI_REGISTRY}/homepages/${
                    athenaEvent.app ?? declinaison
                }`,
            },
            objectType: "Agent",
        },
        verb: athenaEvent.data.correct ? VERBS.passed : VERBS.failed,
        object: {
            id: `${XAPI_REGISTRY}/exercise/${athenaEvent.categoryData.learningItem.id}`,
            objectType: "Activity",
        },
        result: {
            score: {
                scaled: athenaEvent.data.score,
                raw: athenaEvent.data.score,
                min: 0,
                max: 1,
            },
            success: athenaEvent.data.correct,
            response: JSON.stringify(athenaEvent.data.answer),
            duration: durationToISO(athenaEvent.data.duration / 1_000),
        },
        context: {
            registration: athenaEvent.sessionId,
            contextActivities: {
                category: [
                    {
                        id: WORKMODES_CATEGORIES[
                            eventWorkmodeToWorkModeType(
                                athenaEvent.categoryData.workmode
                            ) as WorkmodesWithStatements
                        ],
                    },
                    eventToCategory(athenaEvent) ?? ({} as ContextActivity),
                ],
                parent: resultEventToParent(athenaEvent),
            },
            extensions: {
                [`${XAPI_REGISTRY}/extensions/common`]: {
                    isInitialTest:
                        athenaEvent.data.testContext === "initial-test",
                    isAdaptiveTest:
                        athenaEvent.data.testContext === "adaptive-test",
                },
                // TODO: use value from AthenaEvent once added
                [`${XAPI_REGISTRY}/extensions/adaptive-test`]: 1,
            },
        },
    };
};

const resultEventToParent = (
    athenaEvent: AthenaEvent<"result", ResultEventType>
): Required<Context>["contextActivities"]["parent"] => {
    const workmodeType = eventWorkmodeToWorkModeType(
        athenaEvent.categoryData.workmode
    );
    let parent: Required<Context>["contextActivities"]["parent"];
    if (
        workmodeType === WorkModeType.SoloAI ||
        workmodeType === WorkModeType.Revision
    ) {
        parent = [
            {
                id: `${XAPI_REGISTRY}/module/${
                    athenaEvent.categoryData.parentItems![2].id
                }/objective/${
                    athenaEvent.categoryData.parentItems![1].id
                }/activity/${athenaEvent.categoryData.parentItems![0].id}`,
                objectType: "Activity",
            },
            {
                id: `${XAPI_REGISTRY}/learning-set/${
                    athenaEvent.categoryData.parentItems![2].id
                }`,
                objectType: "Activity",
            },
        ];
    } else {
        parent = [
            {
                id: `${XAPI_REGISTRY}/learning-set/${
                    athenaEvent.categoryData.parentItems![0].id
                }`,
                objectType: "Activity",
            },
        ];
    }
    return parent;
};
