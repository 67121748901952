import { useContext } from "react";
import { sessionStore } from "../contexts/SessionContext";
import { ThemeType } from "../interfaces/Theme";

const useTheme = (): ThemeType => {
    const {
        session: { theme },
    } = useContext(sessionStore);

    return theme;
};
export default useTheme;
