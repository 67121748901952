export const defaultImages = {
    teacherClusteringEmpty: "teacher-clustering_empty.svg",
    teacherResourcesWorkshopRightColumn:
        "teacher-resources-workshop_rightColumn.svg",
    teacherResourcesPlaylistsDraft: "teacher-resources-playlists_draft.svg",
    teacherResourcesPlaylistsSent: "teacher-resources-playlists_sent.svg",
    teacherResourcesPlaylistsArchived:
        "teacher-resources-playlists_archived.svg",
    teacherResourcesPlaylistsRightColumn:
        "teacher-resources-playlists_rightColumn.svg",
    teacherProgressionOverviewEmpty: "teacher-progression_overview-empty.svg",
    studentResourcesWorkshopEmpty: "student-resources-workshop_empty.svg",
    studentResourcesPlaylistsRightColumn:
        "student-resources-playlists_rightColumn.svg",
    studentResourcesPlaylistsEmpty: "student-resources-playlists_empty.svg",
    studentMyWorkToDo: "student-my_work-to_do.svg",
    studentMyWorkInProgress: "student-my_work-in_progress.svg",
    studentMyWorkDone: "student-my_work_done.svg",
    studentMyAssessmentsEmpty: "student-my_assessments-empty.svg",
    studentDuoEmpty: "student-duo_empty.svg",
    notificationEmpty: "notification_empty.svg",
    playerBackground: "playerBackground.png",
    playerEndOfTest: "playerEndOfTest.svg",
    playerUserReport: "playerUserReport.svg",
    experimentationClosed: "experimentation_closed.svg",
};
