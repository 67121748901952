import { IntlShape, MessageDescriptor } from "react-intl";
import { Page, Pagetype } from "../interfaces/Config";
import { UserType } from "../interfaces/User";
import { pageTitles } from "./messages";
import { FeatureFlags } from "@evidenceb/athena-common/modules/FeatureFlags";

type PageTypesWithTitles = Exclude<
    Pagetype,
    Pagetype.LOGOUT | Pagetype.SWITCH_USER_TYPE
>;
type PageTypesWithI18NTitles = Exclude<
    PageTypesWithTitles,
    Pagetype.CONTENTPAGE | Pagetype.EXTERNAL
>;

export const getPageTitle =
    (intl: IntlShape) =>
    (page: Page, userType: UserType, features: FeatureFlags) => {
        if (
            page.type === Pagetype.CONTENTPAGE ||
            page.type === Pagetype.EXTERNAL
        )
            return getContentOrExternalPageTitle(page, userType);
        const descriptor = getI18NPageTitle(
            page.type as PageTypesWithI18NTitles,
            userType,
            features
        );
        return intl.formatMessage(descriptor);
    };

const getI18NPageTitle = (
    pageType: PageTypesWithI18NTitles,
    userType: UserType,
    features: FeatureFlags
): MessageDescriptor => {
    if (userType === UserType.Student)
        return getStudentPageTitle(pageType, features);
    return getTeacherPageTitle(pageType);
};

const getStudentPageTitle = (
    pageType: PageTypesWithI18NTitles,
    features: FeatureFlags
): MessageDescriptor => {
    switch (pageType) {
        case Pagetype.DASHBOARD:
            return pageTitles.studentAssessment;
        case Pagetype.HOME:
            return pageTitles.home;
        case Pagetype.MODULELIST:
            if (features["cnedModuleList"])
                return pageTitles.standaloneAdaptiveTestModuleList;
            else return pageTitles.studentSoloAIModuleList;
        case Pagetype.WORK:
            return pageTitles.work;
        case Pagetype.WORKMODE_DUO:
            return pageTitles.studentDuosList;
        case Pagetype.WORKMODE_PLAYLIST:
            return pageTitles.studentPlaylistsList;
        case Pagetype.WORKMODE_TUTO:
            return pageTitles.tutosList;
        case Pagetype.WORKMODE_WORKSHOP:
            throw new Error("No title for student workshop");
        case Pagetype.WORK_MODES:
            return pageTitles.studentWorkmodeSelection;
        case Pagetype.WORKMODE_REVISION:
            return pageTitles.revisionPlayer;
        case Pagetype.WORKMODE_EXPERIMENTATION:
            return pageTitles.experimentation;
        default: {
            const _: never = pageType;
            throw new Error(`Pagetype ${pageType} not found`);
        }
    }
};

const getTeacherPageTitle = (
    pageType: PageTypesWithI18NTitles
): MessageDescriptor => {
    switch (pageType) {
        case Pagetype.DASHBOARD:
            return pageTitles.teacherDashboard;
        case Pagetype.HOME:
            return pageTitles.home;
        case Pagetype.MODULELIST:
            return pageTitles.teacherSoloAIModuleList;
        case Pagetype.WORK:
            return pageTitles.work;
        case Pagetype.WORKMODE_DUO:
            return pageTitles.teacherDuosListTitle;
        case Pagetype.WORKMODE_PLAYLIST:
            return pageTitles.teacherPlaylistsList;
        case Pagetype.WORKMODE_TUTO:
            return pageTitles.tutosList;
        case Pagetype.WORKMODE_WORKSHOP:
            return pageTitles.teacherWorkshopsList;
        case Pagetype.WORK_MODES:
            return pageTitles.teacherWorkmodeSelection;
        case Pagetype.WORKMODE_REVISION:
            return pageTitles.revisionPlayer;
        case Pagetype.WORKMODE_EXPERIMENTATION:
            return pageTitles.experimentation;
        default: {
            const _: never = pageType;
            throw new Error(`Pagetype ${pageType} not found`);
        }
    }
};

export const getContentOrExternalPageTitle = (
    page: Pick<Page, "display">,
    userType: UserType
): string => {
    if (typeof page.display.label === "string") return page.display.label;
    if (page.display.label && userType === UserType.Teacher)
        return page.display.label[0];
    if (page.display.label && userType === UserType.Student)
        return page.display.label[1];
    return "";
};
