import React from "react";
import { Helmet } from "react-helmet-async";
import { usePageTitle } from "../../hooks/usePage";
import { MessageDescriptor, useIntl } from "react-intl";

export type Props = {
    title: string | MessageDescriptor;
    description?: string;
    keywords?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    ogUrl?: string;
} & Partial<Parameters<typeof usePageTitle>[1]>;
// This component sets the title of the tab in the browser
const TabTitle = ({
    title: _title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
    ...opts
}: Props) => {
    const intl = useIntl();
    const title =
        typeof _title === "string" ? _title : intl.formatMessage(_title);
    const pageTitle = usePageTitle(title, opts);
    return (
        <Helmet>
            <title>{pageTitle}</title>
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {ogTitle && <meta property="og:title" content={ogTitle} />}
            {ogDescription && (
                <meta property="og:description" content={ogDescription} />
            )}
            {ogImage && <meta property="og:image" content={ogImage} />}
            {ogUrl && <meta property="og:url" content={ogUrl} />}
        </Helmet>
    );
};
export default TabTitle;
