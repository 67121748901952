import React from "react";
import "./EmptyContainer.scss";
import useAssetsDetails from "../../../hooks/useAssetsDetails";
import classNames from "classnames";
import ImageWithFallback from "../../../components/ImageWithFallback/ImageWithFallback";

interface Props {
    img?: string | undefined;
    defaultImg?: string;
    children?: React.ReactNode;
    className?: string;
}

const EmptyContainer = ({ children, img, defaultImg, className }: Props) => {
    const assetsDetails = useAssetsDetails();
    return (
        <div className={classNames("empty-container", className)}>
            <ImageWithFallback
                src={img}
                fallbackImage={defaultImg}
                assetsDetails={assetsDetails}
                className="empty-container__default"
            />
            {children}
        </div>
    );
};

export default EmptyContainer;
