import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { headerIcons } from "../interface";
import { configStore } from "../../../contexts/ConfigContext";
import { isNavlinkActive, showInHeaderNav } from "../utils";
import { useUserType } from "../../../hooks/useUserInfo";
import { defineMessages, useIntl } from "react-intl";
import { NavLink, useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/dataRetrieval";
import { UserType } from "../../../interfaces/User";
import { useFeatureFlags } from "@evidenceb/athena-common/modules/FeatureFlags";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import PopoverMenu from "../../../design-system-components/PopoverMenu/PopoverMenu";
import { Page, Pagetype } from "../../../interfaces/Config";
import { getPageTitle } from "../../../utils/pages";

import "./DesktopNav.scss";
import AccessibleButton from "../../AccessibleButton/AccessibleButton";

const DesktopNav = () => {
    const intl = useIntl();
    const history = useHistory();
    const userType = useUserType();
    const {
        config: { pages },
    } = useContext(configStore);
    const features = useFeatureFlags();

    const itemList = useMemo(
        () =>
            pages
                .filter((page) => showInHeaderNav(userType, page))
                .map((elem) => ({
                    ...elem,
                    iconPath: headerIcons[elem.type] || "",
                })),
        [pages, userType]
    );
    const [menuItems, setMenuItems] = useState<(Page & { iconPath: string })[]>(
        []
    );

    // The algo used to hide overflowing items in the menu is: If the page
    // shrinks, place last displayed item in the menu. Then the menu change
    // triggers the effect again but with the updated UI, and we check if it is
    // still overflowing, and so on and so forth. If the page grows, we remove
    // everything form the menu and treat it as a shrink
    const listRef = useRef<HTMLUListElement>(null);
    const prevWidthRef = useRef<number>(Infinity);
    useEffect(() => {
        const handleShrink = (nextWidth: number) => {
            if (!listRef.current) return;
            prevWidthRef.current = nextWidth;
            if (!checkOverflow(listRef.current)) return;
            const lastItemNotInMenu = itemList
                .filter((item) =>
                    menuItems.every((menuItem) => menuItem.type !== item.type)
                )
                .reverse()[0];
            if (lastItemNotInMenu)
                setMenuItems((curr) => [...curr, lastItemNotInMenu]);
        };
        const handleGrow = () => {
            setMenuItems([]);
            prevWidthRef.current = Infinity; // Set as Infinity to treat next round through the useEffect as a shrink
        };
        const handleResize = () => {
            const nextWidth = window.innerWidth;
            if (prevWidthRef.current >= nextWidth) handleShrink(nextWidth);
            else handleGrow();
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            return window.removeEventListener("resize", handleResize);
        };
    }, [menuItems, itemList]);

    return (
        <nav
            aria-label={intl.formatMessage(messages.mainMenu)}
            className="desktop-header"
        >
            <ul ref={listRef} className="header__navigation">
                {itemList
                    .filter((item) =>
                        menuItems.every(
                            (menuItem) => menuItem.type !== item.type
                        )
                    )
                    .map((page, i) => {
                        const title = getPageTitle(intl)(
                            page,
                            userType,
                            features
                        );

                        return (
                            <li
                                key={`navItem-${page.type}-${i}`}
                                data-type={page.iconPath}
                            >
                                {page.type === Pagetype.EXTERNAL ? (
                                    <a
                                        rel="noreferrer"
                                        href={getUrl(page, userType)}
                                        target="_blank"
                                        title={title}
                                    >
                                        {title}
                                    </a>
                                ) : (
                                    <NavLink
                                        className={`${
                                            userType === UserType.Teacher
                                                ? "--teacher"
                                                : "--student"
                                        }`}
                                        to={`/${getUrl(page, userType)}`}
                                        title={title}
                                        isActive={isNavlinkActive(
                                            page,
                                            pages,
                                            userType
                                        )}
                                        exact
                                    >
                                        <Icon
                                            className="--icon-idle"
                                            path={
                                                "nav-bar_" +
                                                page.iconPath +
                                                "_outline"
                                            }
                                            size={20}
                                        />
                                        <Icon
                                            className="--icon-active"
                                            path={"nav-bar_" + page.iconPath}
                                            size={20}
                                            style={{
                                                color: "var(--element-evidenceb-default)",
                                            }}
                                        />
                                        <span>{title}</span>
                                    </NavLink>
                                )}
                            </li>
                        );
                    })}
            </ul>
            {menuItems.length > 0 && (
                <PopoverMenu
                    trigger={
                        <AccessibleButton className="desktop-nav__popover-trigger">
                            <span>{intl.formatMessage(messages.menu)}</span>
                            <Icon path="chevron_down" size="medium" />
                        </AccessibleButton>
                    }
                    popoverPlacement="bottom start"
                    onAction={(key) => {
                        history.push(
                            `/${getUrl(menuItems[Number(key)], userType)}`
                        );
                    }}
                >
                    {menuItems.map((elem, index) => (
                        <PopoverMenu.Item
                            textValue={getPageTitle(intl)(
                                elem,
                                userType,
                                features
                            )}
                            key={index}
                        >
                            <a
                                className="desktop-nav__popover-item"
                                href={getUrl(elem, userType)}
                            >
                                <Icon path={elem.iconPath} size="medium" />
                                <span>
                                    {getPageTitle(intl)(
                                        elem,
                                        userType,
                                        features
                                    )}
                                </span>
                            </a>
                        </PopoverMenu.Item>
                    ))}
                </PopoverMenu>
            )}
        </nav>
    );
};
export default DesktopNav;

const messages = defineMessages({
    mainMenu: {
        id: "a11y-mainMenu",
        defaultMessage: "Main menu",
    },
    menu: {
        id: "a11y-menu",
        defaultMessage: "Menu",
    },
});

function checkOverflow(el: HTMLElement) {
    const curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";

    const HARMLESS_OVERFLOW = 5;
    const isOverflowing = el.clientHeight + HARMLESS_OVERFLOW < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
}
