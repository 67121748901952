import { Button } from "@evidenceb/athena-common/design-system/Button";
import React, { FormEvent, useState } from "react";
import "./FeedbackForm.scss";
import FeedbackQuestion from "./FeedbackQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import { useImages } from "../../hooks/useConfig";
import { defaultImages } from "../../utils/defaultImages";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import useAssetsDetails from "../../hooks/useAssetsDetails";

interface Props {
    question: FeedbackQuestion;
    onSubmit: (question: FeedbackQuestion, answer: string) => void;
    submitButtonLabel: string;
    postSubmitMessageTitle: string;
    postSubmitMessageBody: string;
}

const NonSubmittedFeedbackForm = ({
    question,
    onSubmit,
    submitButtonLabel,
}: Omit<Props, "postSubmitMessageTitle" | "postSubmitMessageBody">) => {
    const [answer, setAnswer] = useState<string | null>(null);
    const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        onSubmit(question, answer!);
    };
    return (
        <form
            className="motivational-message__non-submitted-feedback-form"
            onSubmit={handleSubmit}
        >
            {/*
             * Note: if in the future other question types are added, here we'll
             * need some sort of switch to select which component to use.
             */}
            <SingleChoiceQuestion
                question={question}
                value={answer}
                onChange={setAnswer}
            />
            <div className="motivational-message__non-submitted-feedback-form__cta-container">
                <Button
                    type="submit"
                    disabled={answer === null}
                    label={submitButtonLabel}
                    size="small"
                />
            </div>
        </form>
    );
};

const SubmittedFeedbackForm = ({
    postSubmitMessageTitle,
    postSubmitMessageBody,
}: Pick<Props, "postSubmitMessageTitle" | "postSubmitMessageBody">) => {
    const images = useImages();
    const userReportImage = images?.player?.userReport;
    const assetsDetails = useAssetsDetails();
    return (
        <div className="motivational-message__submitted-feedback-form">
            <h5>{postSubmitMessageTitle}</h5>

            <ImageWithFallback
                fallbackImage={defaultImages.playerUserReport}
                src={userReportImage}
                assetsDetails={assetsDetails}
                size="small"
            />

            <p>{postSubmitMessageBody}</p>
        </div>
    );
};

const FeedbackForm = ({
    question,
    onSubmit,
    submitButtonLabel,
    postSubmitMessageTitle,
    postSubmitMessageBody,
}: Props) => {
    const [submitted, setSubmitted] = useState(false);
    return submitted ? (
        <SubmittedFeedbackForm
            postSubmitMessageTitle={postSubmitMessageTitle}
            postSubmitMessageBody={postSubmitMessageBody}
        />
    ) : (
        <NonSubmittedFeedbackForm
            question={question}
            submitButtonLabel={submitButtonLabel}
            onSubmit={(...args) => {
                setSubmitted(true);
                onSubmit(...args);
            }}
        />
    );
};
export default FeedbackForm;
